'use strict';

let currentListItem = 1;

module.exports = function () {
    const $listSlider = $('.js-list-slider');
    const slideInterval = $listSlider.data('slide-every-xseconds') || 7; // set custom seconds in data-slide-every-xseconds attribute or use default 7s
    let $listSliderItems = $listSlider.children('li');
    setInterval(() => {
        currentListItem++;
        if (currentListItem > $listSliderItems.length) currentListItem = 1;
        $listSliderItems.removeClass('is-active').eq(currentListItem - 1).addClass('is-active');
    }, slideInterval * 1000);
};
