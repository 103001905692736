'use strict';

const desktopBreakpoint = 1024;
const mediaQuery = window.matchMedia('(min-width: ' + desktopBreakpoint + 'px)');

module.exports = function () {
    mediaQuery.onchange = (e) => {
        if (e.matches) {
            /* the viewport is more than 1024px pixels wide */
            $('body').trigger('viewport.onChange', ['desktop']);
            $('body').trigger('viewport.onChangeToDesktop');
        } else {
            /* the viewport is 1024px pixels wide or less */
            $('body').trigger('viewport.onChange', ['mobile']);
            $('body').trigger('viewport.onChangeToMobile');
        }
    };
};
