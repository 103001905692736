'use strict';

module.exports = function () {
    var $backToTop = $('.js-back-to-top');
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 400) {
            $backToTop.addClass('showed');
        } else {
            $backToTop.removeClass('showed');
        }
    });

    $backToTop.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
        // just trigger focus on some unfocusable but top header part to avoid :focus-visible state
        $('.js-header-content').trigger('focus');
    });
};
