'use strict';

let util = require('../wishlist/wishlistUtil');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    if (data.success) {
        icon.toggleClass('is-filled');
    }
}

module.exports = {
    wishlistRerenderHearts: util.wishlistRerenderHearts,
    registerHandlers: util.registerHandlers,
    handleWishlist: function () {
        $('body').on('click', '.js-wishlist-tile', function (e) {
            e.preventDefault();
            const $icon = $(this);
            const heartIsFilledClass = 'is-filled';
            const isPresentInWishList = $icon.hasClass(heartIsFilledClass);
            const $url = isPresentInWishList ? $icon.data('remove-url') : $icon.data('add-url');
            const parent = $icon.closest('.js-product');
            const pid = parent.attr('data-pid');
            const optionId = $icon.closest('.product-detail').find('.product-option').attr('data-option-id') || null;
            const optionVal = $icon.closest('.product-detail').find('.options-select option:selected').attr('data-value-id') || null;

            if (!$url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: $url,
                type: isPresentInWishList ? 'get' : 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessageAndChangeIcon(data, $icon);
                    if (isPresentInWishList) {
                        $icon.removeClass(heartIsFilledClass);
                        util.removePidFromWishList(pid);
                    } else {
                        $icon.addClass(heartIsFilledClass);
                        util.addPidToWishList(pid);
                    }
                    $('body').trigger('wishlist:updateCount');
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, $icon);
                }
            });
        });
    }
};
