'use strict';

/**
 * remove pid from wishlistProductList
 * @param {string} pid - pid for removing
 */
function removePidFromWishList(pid) {
    const index = window.wishlistProductList.indexOf(pid);
    if (index !== -1) {
        window.wishlistProductList.splice(index, 1);
    }
}

/**
 * add pid to wishlistProductList
 * @param {string} pid - pid for adding
 */
function addPidToWishList(pid) {
    window.wishlistProductList.push(pid);
}

/**
 * register listeners body for wishlist
 */
function registerHandlers() {
    const $body = $('body');

    $body.on('wishlist:updateHearts', wishlistRerenderHearts);
    $body.on('wishlist:updateCount', wishlistRerenderCount);
}

/**
 * Rerender hearts
 */
function wishlistRerenderHearts() {
    const wishlist = window.wishlistProductList || [];
    const $nodes = $('.js-wishlist-tile');

    $nodes.each((index, item) => {
        const $node = $(item);
        const productId = $node.closest('.js-product').attr('data-pid');
        $node.toggleClass('is-filled', wishlist.includes(productId));
    });
}

/**
 * Rerender count in header
 * @param {Object} event - JS event
 * @param {string|int} newValue - newValue
 */
function wishlistRerenderCount(event, newValue) {
    const data = (newValue === undefined) ? window.wishlistProductList.length : newValue;
    $('.js-wishlist-quantity').text(data || '');
    $('.js-wishlist').toggleClass('is-filled', !!data);
    $('body').trigger('wishlist:updateHearts');
}

module.exports = {
    addPidToWishList: addPidToWishList,
    removePidFromWishList: removePidFromWishList,
    registerHandlers: registerHandlers,
    wishlistRerenderHearts: wishlistRerenderHearts,
    wishlistRerenderCount: wishlistRerenderCount
};
