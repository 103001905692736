'use strict';

var LazyLoad = require('vanilla-lazyload');

var inst = new LazyLoad({
    callback_error: (img) => {
        var $img = $(img);
        var $pictureSources = $(img).siblings('source');
        var srcsetUrls = `${window.staticFolder}images/noimagemedium.png 1x, ${window.staticFolder}images/noimagelarge.png 2x`;
        // skip if element has something other than 'data-src' attribute (i.e. data-bg error will be handled via styles)
        if ($img.attr('data-srcset')) $img.attr('srcset', srcsetUrls);
        if ($img.attr('data-src')) $img.attr('src', `${window.staticFolder}images/noimagemedium.png`);

        if ($pictureSources.length) $pictureSources.attr('srcset', srcsetUrls);
    }
});

module.exports = {
    inst: inst,
    update: () => inst.update()
};
